<template>
    <form ref="checkForm" @submit.prevent="invoice()">
        <div
            class="table-responsive"
            style="height: 550px; resize: both"
            ref="scrollDiv"
            @scroll="handleTableScroll"
        >
            <sortable-table2
                ref="sortable"
                v-model="items"
                :default-sort-key="sort.key"
                :default-sort-asc="sort.asc"
                :session-name="sessionName"
            >
                <template #default="{ setIcon, sort }">
                    <table
                        class="table table-borderless table-sm text-nowrap mb-0"
                        style="table-layout: fixed"
                    >
                        <thead class="sticky-top bg-body">
                            <tr>
                                <th class="text-center" style="width: 20px">
                                    <input
                                        type="checkbox"
                                        value="true"
                                        v-model="toggle"
                                    />
                                </th>
                                <th class="text-center" style="width: 25px">
                                    {{ dg.SequentialNumber }}
                                </th>
                                <th
                                    class="text-center"
                                    style="width: 70px; cursor: pointer"
                                    @click="sort('SALES_NO')"
                                >
                                    {{ dg.SalTSales.SALES_NO
                                    }}<font-awesome-icon
                                        :icon="setIcon('SALES_NO')"
                                    />
                                </th>
                                <th
                                    class="text-center"
                                    style="width: 70px; cursor: pointer"
                                    @click="sort('SALES_DATE')"
                                >
                                    {{ dg.SalTSales.SALES_DATE
                                    }}<font-awesome-icon
                                        :icon="setIcon('SALES_DATE')"
                                    />
                                </th>
                                <th class="text-center" style="width: 40px">
                                    {{ dg.SalTSales.STATUS }}
                                </th>
                                <th
                                    class="text-center"
                                    style="width: 55px; cursor: pointer"
                                    @click="sort('KOKYK_ID')"
                                >
                                    {{ dg.KOKYK_ID
                                    }}<font-awesome-icon
                                        :icon="setIcon('KOKYK_ID')"
                                    />
                                </th>
                                <th class="text-center" style="width: 120px">
                                    {{ dg.SalTSales.CLNIC_NM }}
                                </th>
                                <th class="text-center" style="width: 30px">
                                    {{ dg.count }}
                                </th>
                                <th class="text-center" style="width: 60px">
                                    {{ dg.SalTSales.PRICE }}
                                </th>
                                <th class="text-center" style="width: 50px">
                                    {{ dg.SalTSales.TAX }}
                                </th>
                                <th class="text-center" style="width: 70px">
                                    {{ dg.SalTSales.TPRICE }}
                                </th>
                                <th class="text-center" style="width: 40px">
                                    {{ dg.SalTSales.CONT }}
                                </th>
                                <th class="text-center" style="width: 100px">
                                    {{ dg.SalTSales.RECE }}
                                </th>
                                <th class="text-center" style="width: 195px">
                                    {{ dg.SalTSales.nextInv }}
                                </th>
                                <th class="text-center" style="width: 195px">
                                    {{ dg.SalTSales.SALES_NOTE1 }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template
                                v-for="(item, index) in items"
                                :key="index"
                            >
                                <!-- prettier-ignore -->
                                <tr style="border-top: 1px solid var(--bs-border-color)">
                                    <td>
                                        <input
                                            type="checkbox"
                                            name="sales"
                                            :value="item.SALES_NO"
                                            v-model="checkedSales"
                                        />
                                    </td>
                                    <td class="text-end">{{ index + 1 }}</td>
                                    <td>
                                        <a
                                            href="#"
                                            @click.prevent.stop="
                                                edit(item.SALES_NO)
                                            "
                                            >{{ item.SALES_NO }}</a
                                        >
                                    </td>
                                    <td>
                                        {{
                                            item.SALES_DATE?.replaceAll(
                                                "-",
                                                "/"
                                            )
                                        }}
                                    </td>
                                    <td>{{ salesStatusList[item.STATUS] }}</td>
                                    <td>{{ item.KOKYK_ID }}</td>
                                    <td>{{ strLimit(item.CLNIC_NM, 20) }}</td>
                                    <td class="text-end">
                                        {{ item.salTSalesDtls?.length }}
                                    </td>
                                    <td class="text-end">
                                        {{
                                            parseInt(
                                                item.PRICE
                                            ).toLocaleString()
                                        }}
                                    </td>
                                    <td class="text-end">
                                        {{
                                            parseInt(item.TAX).toLocaleString()
                                        }}
                                    </td>
                                    <td class="text-end">
                                        {{
                                            parseInt(
                                                item.TPRICE
                                            ).toLocaleString()
                                        }}
                                    </td>
                                    <td>{{ contKindList[item.CONT_KIND] }}</td>
                                    <td>{{ item.salTRece.RECE_NAME }}</td>
                                    <td>{{ nextInv(item) }}</td>
                                    <td>
                                        {{ strLimit(item.SALES_NOTE1, 20) }}
                                    </td>
                                </tr>
                                <template v-if="showDetail">
                                    <tr
                                        v-for="(
                                            dtl, index2
                                        ) in item.salTSalesDtls"
                                        :key="index2"
                                        style="background: #e2e3e5"
                                    >
                                        <td
                                            colspan="5"
                                            style="
                                                background: var(--bs-body-bg);
                                            "
                                        ></td>
                                        <td class="text-end">#{{ dtl.RNO }}</td>
                                        <td colspan="2">
                                            {{ dtl.PRODUCT_CODE }}/{{
                                                strLimit(
                                                    productList[
                                                        dtl?.PRODUCT_CODE
                                                    ],
                                                    20
                                                )
                                            }}
                                        </td>
                                        <td class="text-end">
                                            {{
                                                parseInt(
                                                    dtl.UPRICE
                                                ).toLocaleString()
                                            }}
                                        </td>
                                        <td class="text-end">
                                            {{
                                                parseInt(
                                                    dtl.CNT
                                                ).toLocaleString()
                                            }}
                                        </td>
                                        <td class="text-end">
                                            {{
                                                parseInt(
                                                    dtl.PRICE
                                                ).toLocaleString()
                                            }}
                                        </td>
                                        <td colspan="2"></td>
                                        <td>{{ strLimit(dtl.NOTE1, 15) }}</td>
                                        <td>{{ strLimit(dtl.NOTE1, 15) }}</td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </template>
            </sortable-table2>
        </div>
        <div class="table-responsive">
            <table
                class="table table-borderless table-sm text-nowrap mb-0"
                style="table-layout: fixed"
            >
                <thead>
                    <tr>
                        <th style="width: 20px"></th>
                        <th style="width: 25px"></th>
                        <th style="width: 70px"></th>
                        <th style="width: 70px"></th>
                        <th style="width: 40px"></th>
                        <th style="width: 55px"></th>
                        <th style="width: 120px"></th>
                        <th class="text-end" style="width: 30px"></th>
                        <th class="text-end" style="width: 60px"></th>
                        <th class="text-end" style="width: 50px"></th>
                        <th style="width: 70px"></th>
                        <th style="width: 40px"></th>
                        <th style="width: 100px"></th>
                        <th style="width: 195px"></th>
                        <th style="width: 195px"></th>
                        <th style="width: 12px"></th>
                    </tr>
                </thead>
                <sum-table-footer :items="items">
                    <template #default="{ sumPrice, sumTax, sumTPrice }">
                        <tfoot class="sticky-bottom bg-body fw-bold">
                            <tr>
                                <!-- 件数 --><!-- 小計 -->
                                <td colspan="6">
                                    {{ dg.Withselected
                                    }}<button
                                        type="submit"
                                        class="btn btn-primary btn-sm"
                                    >
                                        {{ dg.Invoice }}
                                    </button>
                                </td>
                                <td colspan="2">
                                    {{ dg.numRecord
                                    }}<span class="ms-5">{{
                                        items?.length.toLocaleString()
                                    }}</span>
                                </td>
                                <td
                                    colspan="2"
                                    class="ps-3"
                                    style="width: 4rem; letter-spacing: 1.5rem"
                                >
                                    {{ dg.price }}
                                </td>
                                <td class="text-end">
                                    &yen;{{ sumPrice.toLocaleString() }}
                                </td>
                                <td colspan="4"></td>
                            </tr>
                            <tr>
                                <!-- 消費税 -->
                                <td colspan="8">
                                    <!-- csvボタン -->
                                    <csv-button
                                        :button-name="csvInfo.buttonTitle"
                                        :file-name="csvInfo.fileName"
                                        :ids="ids"
                                        :url="csvInfo.url"
                                    ></csv-button>
                                </td>
                                <td colspan="2" class="ps-3">
                                    {{ dg.tax }}
                                </td>
                                <td class="text-end">
                                    &yen;{{ sumTax.toLocaleString() }}
                                </td>
                                <td colspan="4"></td>
                            </tr>
                            <tr>
                                <!-- 合計金額 -->
                                <td colspan="8"></td>
                                <td colspan="2" class="ps-3">
                                    {{ dg.tPrice }}
                                </td>
                                <td class="text-end">
                                    &yen;{{ sumTPrice.toLocaleString() }}
                                </td>
                                <td colspan="4"></td>
                            </tr>
                        </tfoot>
                    </template>
                </sum-table-footer>
            </table>
        </div>
    </form>
</template>

<script>
import CsvButton from "../CsvButton.vue";
import Mixin from "../mixins/mixin";
import SortableTable2 from "../tables/SortableTable2.vue";
import SumTableFooter from "../tables/SumTableFooter.vue";

export default {
    name: "SalesIndexTable",
    components: {
        CsvButton,
        SortableTable2,
        SumTableFooter,
    },
    mixins: [Mixin],
    emits: ["handleTableScroll", "update:model-value"],
    props: {
        modelValue: Array,
        contKindList: Object,
        dg: Object,
        documentRoot: String,
        productList: Object,
        salesStatusList: Object,
        sessionName: String,
        showDetail: Boolean,
        sort: Object,
        ids: Array,
    },
    data() {
        return {
            toggle: false,
            checkedSales: [],
        };
    },
    methods: {
        handleTableScroll(e) {
            this.$emit("handleTableScroll", e);
        },
        init() {
            this.$refs.sortable.initialSort();
        },
        edit(salesNo) {
            location.href = `${this.documentRoot}/sales/${salesNo}/edit`;
        },
        stock(salesNo) {
            location.href = `${this.documentRoot}/stock?SALES_NO=${salesNo}`;
        },
        invoice() {
            // 1つ以上選択されているか？
            if (this.checkedSales.length === 0) {
                alert("1つ以上選択してください");
                return;
            }

            const checkedSalesList = this.items.reduce(
                (acc, { SALES_NO, KOKYK_ID, B_KOKYK_ID }) => {
                    return [
                        ...acc,
                        ...(this.checkedSales.includes(SALES_NO)
                            ? [{ SALES_NO, KOKYK_ID, B_KOKYK_ID }]
                            : []),
                    ];
                },
                []
            );

            // 全ての請求先顧客が入力有/無で統一されているか？
            if (
                !checkedSalesList.every(
                    ({ B_KOKYK_ID }) =>
                        checkedSalesList[0].B_KOKYK_ID === B_KOKYK_ID
                )
            ) {
                alert("請求先顧客の値が統一されていません。");
                return;
            }

            // 請求先顧客が無い場合
            if (!checkedSalesList[0].B_KOKYK_ID) {
                // 顧客が統一されているか？
                if (
                    !checkedSalesList.every(
                        ({ KOKYK_ID }) =>
                            checkedSalesList[0].KOKYK_ID === KOKYK_ID
                    )
                ) {
                    alert("異なる顧客番号が含まれています。");
                    return;
                }
                console.log("1 請求先顧客なし");
                // 請求先顧客が有る場合
            } else {
                // 請求先顧客が販売に含まれる場合
                if (
                    checkedSalesList.some(
                        ({ KOKYK_ID }) =>
                            checkedSalesList[0].B_KOKYK_ID === KOKYK_ID
                    )
                ) {
                    console.log("2 請求先顧客が販売に含まれる場合");
                } else {
                    console.log("3 請求先顧客が販売に含まれるない");
                }
            }

            const list = this.checkedSales.join("&SALES_NO[]=");

            window.open(
                `${this.documentRoot}/invoice/sales?SALES_NO[]=${list}`,
                "_blank"
            );
        },
        nextInv(item) {
            if (item.DRAW_DATE.length) {
                const string = item.DRAW_DATE.join("月、");
                return `[${string}月]`;
            } else if (item.RECE_NDATE) {
                const date = new Date(item.RECE_NDATE);
                return new Intl.DateTimeFormat("ja", {
                    year: "numeric",
                    month: "2-digit",
                }).format(date);
            }
            return "";
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:model-value", value);
            },
        },
        csvInfo() {
            return this.showDetail
                ? {
                      fileName: "販売詳細一覧.csv",
                      url: "/sales/csv/detail",
                      buttonTitle: this.dg.csvDetail,
                  }
                : {
                      fileName: "販売一覧.csv",
                      url: "/sales/csv/index",
                      buttonTitle: this.dg.csv,
                  };
        },
    },
    watch: {
        toggle: function (val) {
            this.checkedSales = val
                ? this.items.reduce((acc, cur) => [...acc, cur.SALES_NO], [])
                : [];
        },
    },
};
</script>
