<template>
    <div
        class="table-responsive"
        ref="scrollDiv"
        :style="{ resize: 'both', height: divHeight }"
        @scroll="handleTableScroll"
    >
        <sortable-table2
            ref="sortable"
            v-model="items"
            :default-sort-key="sort.key"
            :default-sort-asc="sort.asc"
            :session-name="sessionName"
        >
            <template #default="{ setIcon, sort }">
                <table class="table table-hover table-sm text-nowrap">
                    <thead class="sticky-top bg-body">
                        <tr>
                            <th style="min-width: 2rem">
                                {{ dg.SequentialNumber }}
                            </th>
                            <th
                                style="min-width: 7rem; cursor: pointer"
                                @click="sort('STOCK_NO')"
                            >
                                {{ dg.SalTStock.STOCK_NO
                                }}<font-awesome-icon
                                    :icon="setIcon('STOCK_NO')"
                                />
                            </th>
                            <th
                                style="min-width: 6rem; cursor: pointer"
                                @click="sort('SALES_NO')"
                            >
                                {{ dg.SalTStock.SALES_NO
                                }}<font-awesome-icon
                                    :icon="setIcon('SALES_NO')"
                                />
                            </th>
                            <th
                                style="min-width: 6rem; cursor: pointer"
                                @click="sort('STOCK_DATE')"
                            >
                                {{ dg.SalTStock.STOCK_DATE
                                }}<font-awesome-icon
                                    :icon="setIcon('STOCK_DATE')"
                                />
                            </th>
                            <th
                                style="min-width: 6rem; cursor: pointer"
                                @click="sort('IN_DATE')"
                            >
                                {{ dg.SalTStock.IN_DATE
                                }}<font-awesome-icon
                                    :icon="setIcon('IN_DATE')"
                                />
                            </th>
                            <th
                                style="min-width: 6rem; cursor: pointer"
                                @click="sort('DELI_DATE')"
                            >
                                {{ dg.SalTStock.DELI_DATE
                                }}<font-awesome-icon
                                    :icon="setIcon('DELI_DATE')"
                                />
                            </th>
                            <th style="min-width: 6rem">
                                {{ dg.SalTStock.STATUS }}
                            </th>
                            <th
                                style="min-width: 5rem; cursor: pointer"
                                @click="sort('salTSales.KOKYK_ID')"
                            >
                                {{ dg.KOKYK_ID
                                }}<font-awesome-icon
                                    :icon="setIcon('salTSales.KOKYK_ID')"
                                />
                            </th>
                            <th style="min-width: 8rem">{{ dg.CLNIC_NM }}</th>
                            <th style="min-width: 9rem">
                                {{ dg.SalTProduct.PRODUCT_NAME }}
                            </th>
                            <th style="min-width: 5rem">
                                {{ dg.SalTStock.MAKER }}
                            </th>
                            <th style="min-width: 8rem">
                                {{ dg.SalTStock.PRODNAME }}
                            </th>
                            <th class="text-end" style="min-width: 4rem">
                                {{ dg.SalTStock.UPRICE }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>
                                <a
                                    href="#"
                                    @click.prevent.stop="edit(item.STOCK_NO)"
                                    >{{ item.STOCK_NO }}</a
                                >
                            </td>
                            <td>
                                <a
                                    href="#"
                                    @click.prevent.stop="
                                        editSales(item.SALES_NO)
                                    "
                                    >{{ item.SALES_NO }}</a
                                >
                            </td>
                            <td>{{ item.STOCK_DATE?.replaceAll("-", "/") }}</td>
                            <td>{{ item.IN_DATE?.replaceAll("-", "/") }}</td>
                            <td>{{ item.DELI_DATE?.replaceAll("-", "/") }}</td>
                            <td>{{ stockStatusList[item.STATUS] }}</td>
                            <td>{{ item?.salTSales?.KOKYK_ID }}</td>
                            <td>
                                {{ strLimit(item.salTSales?.CLNIC_NM, 20) }}
                            </td>
                            <td>
                                {{ item?.PRODUCT_CODE }}/{{
                                    strLimit(
                                        item?.salTProduct?.PRODUCT_NAME,
                                        20
                                    )
                                }}
                            </td>
                            <td>{{ strLimit(item.MAKER, 20) }}</td>
                            <td>{{ strLimit(item.PRODNAME, 20) }}</td>
                            <td class="text-end">
                                {{
                                    item.UPRICE
                                        ? parseInt(item.UPRICE).toLocaleString()
                                        : ""
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </sortable-table2>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Mixin from "../mixins/mixin";
import SortableTable2 from "../tables/SortableTable2.vue";

export default {
    name: "StockIndexTable",
    mixins: [Mixin],
    emits: ["handleTableScroll", "update:model-value"],
    components: {
        FontAwesomeIcon,
        SortableTable2,
    },
    props: {
        modelValue: Array,
        dg: Object,
        documentRoot: String,
        stockStatusList: Object,
        divHeight: String,
        sort: Object,
        sessionName: String,
    },
    methods: {
        init() {
            this.$refs.sortable.initialSort();
        },
        edit(primaryKey) {
            if (this.sessionName === "stockIndex") {
                location.href = `${this.documentRoot}/stock/${primaryKey}/edit`;
            } else {
                window.open(
                    `${this.documentRoot}/stock/${primaryKey}/edit`,
                    "_blank"
                );
            }
        },
        editSales(primaryKey) {
            window.open(
                `${this.documentRoot}/sales/${primaryKey}/edit`,
                "_blank"
            );
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
        handleTableScroll(e) {
            this.$emit("handleTableScroll", e);
        },
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:model-value", value);
            },
        },
    },
};
</script>
