<template>
    <div class="position-fixed end-0 p-3" style="top: 3rem; z-index: 10000">
        <div class="toast bg-white" role="alert">
            <div class="toast-header">
                <strong class="me-auto">{{ title }}</strong>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                ></button>
            </div>
            <div class="toast-body white-space-pre-wrap">{{ body }}</div>
        </div>
    </div>
</template>

<script>
import { Toast } from "bootstrap";

export default {
    name: "Toast",
    props: {
        title: String,
        body: String,
        delay: {
            type: Number,
            default: 3000,
        },
    },
    methods: {
        show() {
            const toast = Toast.getOrCreateInstance(
                this.$el.querySelector(".toast"),
                {
                    delay: this.delay,
                }
            );
            toast.show();
        },
    },
};
</script>
