<template>
    <button
        type="button"
        class="btn btn-primary"
        @click="clicked"
        :disabled="disabled"
    >
        <slot></slot>
    </button>
</template>
<script>
export default {
    name: "CancelButton",
    props: {
        confirmFlag: Boolean,
        confirmMessage: String,
        url: String,
        disabled: Boolean,
    },
    methods: {
        clicked() {
            if (this.confirmFlag) {
                if (!confirm(this.confirmMessage)) {
                    return;
                }
            }

            if (history.length === 1) {
                window.close();
                return;
            }

            location.href = this.url;
        },
    },
};
</script>
