<template>
    <div class="table-responsive" style="height: 450px; resize: vertical">
        <sortable-table
            v-model="items"
            default-sort-key="KOKYK_ID"
            :default-sort-asc="true"
            :initialize="true"
        >
            <template #default="{ setIcon, sort }">
                <table
                    class="table table-hover table-sm mb-0 text-nowrap mb-0"
                    style="table-layout: fixed"
                >
                    <thead class="sticky-top bg-white">
                        <tr>
                            <th
                                v-if="edit"
                                class="text-center"
                                style="width: 20px"
                            >
                                <input
                                    type="checkbox"
                                    value="true"
                                    v-model="toggle"
                                />
                            </th>
                            <th class="text-center" style="width: 40px">
                                {{ dg.SequentialNumber }}
                            </th>
                            <th
                                class="text-center"
                                style="width: 120px; cursor: pointer"
                                @click="sort('INV_NO')"
                            >
                                {{ dg.SalTInvoice.INV_NO + "/" + dg.ENO
                                }}<font-awesome-icon
                                    :icon="setIcon('INV_NO')"
                                />
                            </th>
                            <th class="text-center" style="width: 60px">
                                {{ dg.SalTInvoice.STATUS }}
                            </th>
                            <th
                                class="text-center"
                                style="width: 80px; cursor: pointer"
                                @click="sort('KOKYK_ID')"
                            >
                                {{ dg.KOKYK_ID
                                }}<font-awesome-icon
                                    :icon="setIcon('KOKYK_ID')"
                                />
                            </th>
                            <th class="text-center" style="width: 180px">
                                {{ dg.SalTInvoice.CLNIC_NM }}
                            </th>
                            <th class="text-center" style="width: 180px">
                                {{ dg.SalTInvoice.INV_NM }}
                            </th>
                            <th class="text-center" style="width: 40px">
                                {{ dg.numRecord }}
                            </th>
                            <th class="text-center" style="width: 80px">
                                {{ dg.SalTInvoice.PRICE }}
                            </th>
                            <th class="text-center" style="width: 70px">
                                {{ dg.SalTInvoice.TAX }}
                            </th>
                            <th class="text-center" style="width: 90px">
                                {{ dg.SalTInvoice.TPRICE }}
                            </th>
                            <th class="text-center" style="width: auto">
                                {{ dg.SalTInvoice.INV_NOTE1 }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td v-if="edit">
                                <input
                                    type="checkbox"
                                    name="invoice"
                                    :value="item.ID"
                                    v-model="checkedInvoiceModelVal"
                                />
                            </td>
                            <td>{{ index + 1 }}</td>
                            <td v-if="edit">
                                <a
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    data-bs-html="true"
                                    :data-bs-original-title="tooltip(index)"
                                    href="#"
                                    @click.prevent.stop="openInvoice(item.ID)"
                                    >{{ item.INV_NO + "/" + item.ENO }}</a
                                >
                            </td>
                            <td v-else>
                                <span
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    data-bs-html="true"
                                    :data-bs-original-title="tooltip(index)"
                                    >{{ item.INV_NO + "/" + item.ENO }}</span
                                >
                            </td>
                            <td>{{ invoiceStatusList[item.STATUS] }}</td>
                            <td>{{ item.KOKYK_ID }}</td>
                            <td>{{ strLimit(item.CLNIC_NM, 20) }}</td>
                            <td>{{ strLimit(item.INV_NM, 20) }}</td>
                            <td class="text-end">
                                {{ item?.salTInvoiceDtls?.length }}
                            </td>
                            <td class="text-end">
                                {{ parseInt(item.PRICE).toLocaleString() }}
                            </td>
                            <td class="text-end">
                                {{ parseInt(item.TAX).toLocaleString() }}
                            </td>
                            <td class="text-end">
                                {{ parseInt(item.TPRICE).toLocaleString() }}
                            </td>
                            <td>
                                <div>
                                    {{ strLimit(item.INV_NOTE1, 20) }}
                                </div>
                                <!-- 契約期間が終了した販売がある場合、警告メッセージを表示 -->
                                <div
                                    class="alert alert-warning m-0"
                                    v-if="item.contEndedSales.length > 0"
                                >
                                    <div
                                        class="d-flex gap-1 align-items-center"
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-circle-exclamation"
                                        />
                                        <span
                                            >契約期間が終了している販売があります。</span
                                        >
                                    </div>
                                    <ul class="m-0">
                                        <li
                                            v-for="sale in item.contEndedSales"
                                            :key="sale.SALES_NO"
                                            class="d-flex gap-1 align-items-center"
                                        >
                                            <a
                                                :href="`${documentRoot}/sales/${sale.SALES_NO}/edit`"
                                                target="_blank"
                                            >
                                                {{ sale.SALES_NO }}
                                                <font-awesome-icon
                                                    icon="fa-solid fa-arrow-up-right-from-square"
                                                />
                                            </a>
                                            <span>
                                                <span>(</span>
                                                <span>{{
                                                    dg.SalTSales.CONT_END
                                                }}</span>
                                                <span>: </span>
                                                <span>{{ sale.CONT_END }}</span>
                                                <span>)</span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </sortable-table>
    </div>
    <table
        class="table table-borderless table-sm text-nowrap mb-0"
        style="table-layout: fixed"
    >
        <thead>
            <tr>
                <th v-if="edit" style="width: 20px"></th>
                <th style="width: 40px"></th>
                <th style="width: 120px"></th>
                <th style="width: 60px"></th>
                <th style="width: 80px"></th>
                <th style="width: 180px"></th>
                <th style="width: 180px"></th>
                <th style="width: 40px"></th>
                <th style="width: 80px"></th>
                <th style="width: 70px"></th>
                <th style="width: 90px"></th>
                <th style="width: auto"></th>
                <th style="width: 16px"></th>
            </tr>
        </thead>
        <sum-table-footer :items="items">
            <template #default="{ sumPrice, sumTax, sumTPrice }">
                <tfoot class="sticky-bottom bg-white fw-bold">
                    <tr>
                        <template v-if="edit">
                            <td class="" colspan="6">
                                <div class="gap-2 d-flex">
                                    {{ dg.Withselected }}
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        @click="exportToBoneDX"
                                        :disabled="isLoading"
                                    >
                                        {{ dg.ExportToBoneDX }}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        v-if="edit"
                                        @click="print"
                                        :disabled="isLoading"
                                    >
                                        {{ dg.print }}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        v-if="edit"
                                        @click="paid"
                                        :disabled="isLoading"
                                    >
                                        {{ dg.paid }}
                                    </button>
                                </div>
                            </td>
                        </template>
                        <td v-else colspan="5"></td>
                        <!-- 件数 --><!-- 小計 -->
                        <td colspan="2">
                            {{ dg.numRecord
                            }}<span class="ms-5">{{
                                items?.length.toLocaleString()
                            }}</span>
                        </td>
                        <td
                            colspan="2"
                            class="ps-3"
                            style="width: 4rem; letter-spacing: 1.5rem"
                        >
                            {{ dg.price }}
                        </td>
                        <td class="text-end">
                            &yen;{{ sumPrice.toLocaleString() }}
                        </td>
                        <td class=""></td>
                    </tr>
                    <tr>
                        <!-- 消費税 -->
                        <td v-if="edit"></td>
                        <td colspan="7"></td>
                        <td colspan="2" class="ps-3">{{ dg.tax }}</td>
                        <td class="text-end">
                            &yen;{{ sumTax.toLocaleString() }}
                        </td>
                        <td class=""></td>
                    </tr>
                    <tr>
                        <!-- 合計金額 -->
                        <td v-if="edit"></td>
                        <td colspan="7"></td>
                        <td colspan="2" class="ps-3">{{ dg.tPrice }}</td>
                        <td class="text-end">
                            &yen;{{ sumTPrice.toLocaleString() }}
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </template>
        </sum-table-footer>
    </table>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Tooltip } from "bootstrap";
import moment from "moment";
import Mixin from "../mixins/mixin";
import SortableTable from "../tables/SortableTable.vue";
import SumTableFooter from "../tables/SumTableFooter.vue";

export default {
    name: "DrawInputTable",
    mixins: [Mixin],
    emits: [
        "print",
        "paid",
        "export-to-bonedx",
        "update:model-value",
        "update:checked-invoice-model-value",
    ],
    components: {
        FontAwesomeIcon,
        SumTableFooter,
        SortableTable,
    },
    props: {
        modelValue: Array,
        checkedInvoiceModelValue: Array,
        dg: Object,
        documentRoot: String,
        edit: Boolean,
        invoiceStatusList: Object,
        drawYm: String,
        isLoading: Boolean,
    },
    data() {
        return {
            toggle: false,
            tooltips: [],
        };
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
            trigger: "hover",
        });
    },
    methods: {
        tooltip(i) {
            return this.items[i].salTInvoiceDtls.reduce((acc, cur, index) => {
                if (index !== 0) {
                    acc += `<br>---<br>`;
                }

                acc += `商品名:${
                    cur.PRODUCT_NAME
                }<br>単価:&yen;${cur?.UPRICE?.toLocaleString()}<br>数量:${
                    cur.CNT
                }<br>金額:&yen;${cur?.PRICE?.toLocaleString()}<br>関連販売番号:${
                    cur.SALES_NO
                }<br>備考1:${cur.INV_DNOTE1 ?? ""}<br>備考2:${
                    cur.INV_DNOTE2 ?? ""
                }`;

                if (index === this.items[i].salTInvoiceDtls.length - 1) {
                    acc += "</div>";
                }
                return acc;
            }, '<div class="text-start">');
        },
        openInvoice(id) {
            window.open(`${this.documentRoot}/invoice/${id}/edit`, "_blank");
        },
        print() {
            this.$emit("print");
        },
        paid() {
            this.$emit("paid");
        },
        exportToBoneDX() {
            this.$emit("export-to-bonedx");
        },
    },
    computed: {
        items: {
            get() {
                // 引落時点で契約期間が終了している販売を抽出
                const drawYm = moment(this.drawYm).format("YYYY-MM-01");
                const items = this.modelValue;
                items.forEach((item) => {
                    item.contEndedSales = [];
                    item.salTInvoiceDtls
                        .filter((dtl) => dtl.CONT_END && dtl.CONT_END < drawYm)
                        .forEach((dtl) => {
                            if (
                                !item.contEndedSales.some(
                                    (sale) => sale.SALES_NO === dtl.SALES_NO
                                )
                            ) {
                                item.contEndedSales.push({
                                    SALES_NO: dtl.SALES_NO,
                                    CONT_END: dtl.CONT_END,
                                });
                            }
                        });
                });
                return items;
            },
            set(value) {
                this.$emit("update:model-value", value);
            },
        },
        checkedInvoiceModelVal: {
            get() {
                return this.checkedInvoiceModelValue;
            },
            set(value) {
                this.$emit("update:checked-invoice-model-value", value);
            },
        },
    },
    watch: {
        toggle: function (val) {
            this.checkedInvoiceModelVal = val
                ? this.items.reduce((acc, cur) => [...acc, cur.ID], [])
                : [];
        },
    },
};
</script>
