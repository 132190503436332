<template>
    <form ref="checkForm" @submit.prevent="exportToBoneDX()">
        <div
            class="table-responsive"
            style="height: 550px; resize: both"
            ref="scrollDiv"
            @scroll="handleTableScroll"
        >
            <sort-table
                ref="sortable"
                v-model="items"
                v-model:formModelValue="form"
                @fetch="fetch"
            >
                <template #default="{ setIcon, sort }">
                    <table
                        class="table table-hover table-sm text-nowrap mb-0"
                        style="table-layout: fixed"
                    >
                        <thead class="sticky-top bg-body">
                            <tr>
                                <th class="text-center" style="width: 20px">
                                    <input
                                        type="checkbox"
                                        value="true"
                                        v-model="toggle"
                                    />
                                </th>
                                <th style="width: 30px">
                                    {{ dg.SequentialNumber }}
                                </th>
                                <th
                                    style="width: 90px; cursor: pointer"
                                    @click="sort('INV_NO')"
                                >
                                    {{ dg.SalTInvoice.INV_NO }}/{{ dg.ENO
                                    }}<font-awesome-icon
                                        :icon="setIcon('INV_NO')"
                                    />
                                </th>
                                <th
                                    style="width: 75px; cursor: pointer"
                                    @click="sort('INV_DATE')"
                                >
                                    {{ dg.SalTInvoice.INV_DATE
                                    }}<font-awesome-icon
                                        :icon="setIcon('INV_DATE')"
                                    />
                                </th>
                                <th style="width: 50px">
                                    {{ dg.SalTInvoice.STATUS }}
                                </th>
                                <th style="width: 80px">
                                    {{ dg.SalTInvoice.RECE_CODE }}
                                </th>
                                <th
                                    style="width: 65px; cursor: pointer"
                                    @click="sort('KOKYK_ID')"
                                >
                                    {{ dg.KOKYK_ID
                                    }}<font-awesome-icon
                                        :icon="setIcon('KOKYK_ID')"
                                    />
                                </th>
                                <th style="width: 130px">
                                    {{ dg.SalTInvoice.CLNIC_NM }}
                                </th>
                                <th style="width: 150px">
                                    {{ dg.SalTInvoice.INV_NM }}
                                </th>
                                <th class="text-center" style="width: 30px">
                                    {{ dg.count }}
                                </th>
                                <th class="text-end" style="width: 60px">
                                    {{ dg.SalTInvoice.PRICE }}
                                </th>
                                <th class="text-end" style="width: 50px">
                                    {{ dg.SalTInvoice.TAX }}
                                </th>
                                <th class="text-end" style="width: 75px">
                                    {{ dg.SalTInvoice.TPRICE }}
                                </th>
                                <th style="width: 140px">
                                    {{ dg.SalTInvoice.INV_NOTE1 }}
                                </th>
                                <th style="width: 140px">
                                    {{ dg.SalTInvoice.INV_NOTE2 }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template
                                v-for="(item, index) in items"
                                :key="index"
                            >
                                <tr
                                    style="
                                        border-top: 1px solid
                                            var(--bs-border-color);
                                    "
                                >
                                    <td>
                                        <input
                                            type="checkbox"
                                            name="sales"
                                            :value="item.ID"
                                            v-model="checkedInvoices"
                                        />
                                    </td>
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <a
                                            href="#"
                                            @click.prevent.stop="edit(item.ID)"
                                            >{{ item.INV_NO }}-{{ item.ENO }}</a
                                        >
                                    </td>
                                    <td v-if="item.PDF">
                                        <a
                                            :href="`${documentRoot}/storage/invoice/${item.PDF}`"
                                            :download="`${item.PDF}`"
                                            >{{
                                                item.INV_DATE?.replaceAll(
                                                    "-",
                                                    "/"
                                                )
                                            }}</a
                                        >
                                    </td>
                                    <td v-else>
                                        {{
                                            item.INV_DATE?.replaceAll("-", "/")
                                        }}
                                    </td>
                                    <td>
                                        {{ invoiceStatusList[item.STATUS] }}
                                    </td>
                                    <td>{{ receCodeList[item.RECE_CODE] }}</td>
                                    <td>{{ item.KOKYK_ID }}</td>
                                    <td>{{ strLimit(item.CLNIC_NM, 20) }}</td>
                                    <td>{{ strLimit(item.INV_NM, 20) }}</td>
                                    <td class="text-end">
                                        {{ item.salTInvoiceDtls?.length }}
                                    </td>
                                    <td class="text-end">
                                        {{
                                            parseInt(
                                                item.salTInvoiceDtls.reduce(
                                                    (acc, cur) =>
                                                        acc + Number(cur.PRICE),
                                                    0
                                                )
                                            ).toLocaleString()
                                        }}
                                    </td>
                                    <td class="text-end">
                                        {{
                                            parseInt(
                                                item.salTInvoiceDtls.reduce(
                                                    (acc, cur) =>
                                                        acc + Number(cur.PRICE),
                                                    0
                                                ) * 0.1
                                            ).toLocaleString()
                                        }}
                                    </td>
                                    <td class="text-end">
                                        {{
                                            parseInt(
                                                item.salTInvoiceDtls.reduce(
                                                    (acc, cur) =>
                                                        acc + Number(cur.PRICE),
                                                    0
                                                ) * 1.1
                                            ).toLocaleString()
                                        }}
                                    </td>
                                    <td>{{ strLimit(item.INV_NOTE1, 20) }}</td>
                                    <td>{{ strLimit(item.INV_NOTE2, 20) }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
            </sort-table>
        </div>
        <table
            class="table table-borderless table-sm text-nowrap mb-0"
            style="table-layout: fixed"
        >
            <thead class="sticky-top bg-body">
                <tr>
                    <th style="width: 30px"></th>
                    <th style="width: 90px"></th>
                    <th style="width: 75px"></th>
                    <th style="width: 50px"></th>
                    <th style="width: 80px"></th>
                    <th style="width: 65px"></th>
                    <th style="width: 130px"></th>
                    <th style="width: 150px"></th>
                    <th style="width: 30px"></th>
                    <th style="width: 60px"></th>
                    <th style="width: 50px"></th>
                    <th style="width: 75px"></th>
                    <th style="width: 140px"></th>
                    <th style="width: 140px"></th>
                    <th style="width: 13px"></th>
                </tr>
            </thead>
            <tfoot class="sticky-bottom bg-body fw-bold">
                <tr>
                    <!-- 件数 --><!-- 小計 -->
                    <td colspan="7">
                        <!-- csvボタン -->
                        {{ dg.Withselected
                        }}<button type="submit" class="btn btn-primary btn-sm">
                            {{ dg.ExportToBoneDX }}
                        </button>
                    </td>
                    <td colspan="2">
                        {{ dg.numRecord
                        }}<span class="ms-5">{{
                            items?.length.toLocaleString()
                        }}</span>
                    </td>
                    <td
                        colspan="2"
                        class="ps-3"
                        style="width: 4rem; letter-spacing: 1.5rem"
                    >
                        {{ dg.price }}
                    </td>
                    <td class="text-end">
                        &yen;{{ sumPrice.toLocaleString() }}
                    </td>
                    <td colspan="3"></td>
                </tr>
                <tr>
                    <!-- 消費税 -->
                    <td colspan="7">
                        <!-- csvボタン -->
                        <csv-button
                            :button-name="dg.csv"
                            file-name="請求一覧.csv"
                            :ids="ids"
                            url="/invoice/csv/index"
                        ></csv-button>
                    </td>
                    <td colspan="2"></td>
                    <td colspan="2" class="ps-3">{{ dg.tax }}</td>
                    <td class="text-end">&yen;{{ sumTax.toLocaleString() }}</td>
                    <td colspan="3"></td>
                </tr>
                <tr>
                    <!-- 合計金額 -->
                    <td colspan="9"></td>
                    <td colspan="2" class="ps-3">{{ dg.tPrice }}</td>
                    <td class="text-end">
                        &yen;{{ sumTPrice.toLocaleString() }}
                    </td>
                    <td colspan="3"></td>
                </tr>
            </tfoot>
        </table>
    </form>
</template>
<script>
import CsvButton from "../CsvButton.vue";
import Mixin from "../mixins/mixin";
import SortTable from "../tables/SortTable.vue";
import SumTableFooter from "../tables/SumTableFooter.vue";

export default {
    name: "InvoiceIndexTable",
    mixins: [Mixin],
    emits: ["handleTableScroll", "update:model-value", "fetch"],
    components: {
        CsvButton,
        SumTableFooter,
        SortTable,
    },
    props: {
        documentRoot: {
            type: String,
            required: true,
        },
        dg: {
            type: Object,
            required: true,
        },
        formModelValue: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: Array,
            required: true,
        },
        ids: {
            type: Array,
            required: true,
        },
        invoiceStatusList: {
            type: Object,
            required: true,
        },
        productList: {
            type: Object,
            required: true,
        },
        receCodeList: {
            type: Object,
            required: true,
        },
        sessionName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            toggle: false,
            checkedInvoices: [],
        };
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:model-value", value);
            },
        },
        form: {
            get() {
                return this.formModelValue;
            },
            set(value) {
                this.$emit("update:formModelValue", value);
            },
        },
        sumPrice() {
            return this.items.reduce((acc, cur) => {
                const price = cur.salTInvoiceDtls.reduce(
                    (acc2, cur2) => acc2 + Number(cur2.PRICE),
                    0
                );
                return acc + Number(price);
            }, 0);
        },
        sumTax() {
            return Math.round(this.sumPrice * 0.1, 0);
        },
        sumTPrice() {
            return Math.round(this.sumPrice + this.sumTax, 0);
        },
    },
    methods: {
        fetch() {
            this.$emit("fetch");
        },
        edit(primaryKey) {
            location.href = `${this.documentRoot}/invoice/${primaryKey}/edit`;
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
        handleTableScroll(e) {
            this.$emit("handleTableScroll", e);
        },
        async exportToBoneDX() {
            // 1つ以上選択されているか？
            if (this.checkedInvoices.length === 0) {
                alert(this.dg.SalTInvoice.noSelectedInvoiceMessage);
                return;
            }

            const invoiceListNotPrinted = [];
            const checkedInvoicesList = this.items.reduce(
                (acc, { ID, PDF }) => {
                    if (!this.checkedInvoices.includes(ID)) {
                        return acc;
                    }
                    if (!PDF) {
                        invoiceListNotPrinted.push(ID);
                    }
                    acc.push(ID);
                    return acc;
                },
                []
            );

            if (invoiceListNotPrinted.length > 0) {
                alert(this.dg.SalTInvoice.noPrintedInvoiceMessage);
                return;
            }

            if (
                !confirm(
                    `${checkedInvoicesList.length}${this.dg.SalTInvoice.confirmForExportToBoneDXMessage}`
                )
            ) {
                return;
            }

            try {
                const res = await axios.post(`/invoice/export/bonedx`, {
                    ID: checkedInvoicesList,
                });
                location.href = res.data;
            } catch (error) {
                this.errorHandling(error);
            } finally {
                this.isLoading = false;
            }
        },
    },
    watch: {
        toggle: function (val) {
            this.checkedInvoices = val
                ? this.items.reduce((acc, cur) => [...acc, cur.ID], [])
                : [];
        },
    },
};
</script>
