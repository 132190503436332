<template>
    <form ref="form">
        <div class="card mb-1">
            <div class="card-body py-1">
                <div class="row mb-2">
                    <!-- 引落番号 -->
                    <div class="col-1">
                        <label for="DRAW_NO" class="form-label form-label-sm">{{
                            dg.SalTDraw.DRAW_NO
                        }}</label>
                        <input
                            readonly
                            name="DRAW_NO"
                            type="text"
                            class="form-control form-control-sm"
                            id="DRAW_NO"
                            :value="form.DRAW_NO"
                        />
                        <error-message :error="errors.INV_NO"></error-message>
                    </div>
                    <!-- 請求日 -->
                    <div class="col-2">
                        <label for="INV_DATE" class="form-label">{{
                            dg.SalTDraw.INV_DATE
                        }}</label>
                        <input
                            type="date"
                            name="INV_DATE"
                            class="form-control form-control-sm"
                            id="INV_DATE"
                            v-model="form.INV_DATE"
                        />
                        <error-message :error="errors.INV_DATE"></error-message>
                    </div>
                    <!-- 引落日 -->
                    <div class="col-2">
                        <label for="DRAW_DATE" class="form-label">{{
                            dg.SalTDraw.DRAW_DATE
                        }}</label>
                        <input
                            type="date"
                            name="DRAW_DATE"
                            class="form-control form-control-sm"
                            id="DRAW_DATE"
                            v-model="form.DRAW_DATE"
                        />
                        <error-message
                            :error="errors.DRAW_DATE"
                        ></error-message>
                    </div>
                    <!-- 入金日 -->
                    <div class="col-2">
                        <label for="PAY_DATE" class="form-label">{{
                            dg.SalTDraw.PAY_DATE
                        }}</label>
                        <input
                            type="date"
                            name="PAY_DATE"
                            class="form-control form-control-sm"
                            id="PAY_DATE"
                            v-model="form.PAY_DATE"
                        />
                        <error-message :error="errors.PAY_DATE"></error-message>
                    </div>
                    <!-- 状態 -->
                    <div class="col-2">
                        <label for="STATUS" class="form-label">{{
                            dg.SalTDraw.STATUS
                        }}</label>
                        <select
                            name="STATUS"
                            class="form-select form-select-sm"
                            id="STATUS"
                            v-model="form.STATUS"
                            aria-label=".form-select-sm example"
                        >
                            <option
                                v-for="(
                                    invoiceStatus, key
                                ) in invoiceStatusList"
                                :key="key"
                                :value="key"
                            >
                                {{ invoiceStatus }}
                            </option>
                        </select>
                        <error-message :error="errors.STATUS"></error-message>
                    </div>
                </div>
                <div class="row mb-2">
                    <!-- 備考 -->
                    <div class="col-6">
                        <label for="NOTE" class="form-label">{{
                            dg.SalTDraw.NOTE
                        }}</label>
                        <textarea
                            name="NOTE"
                            class="form-control form-control-sm"
                            id="NOTE"
                            v-model="form.NOTE"
                            rows="5"
                        ></textarea>
                    </div>
                    <div class="col-6">
                        <!-- PDF -->
                        <div class="row">
                            <label for="pdf" class="form-label">{{
                                dg.SalTDraw.PDF
                            }}</label>
                            <ul v-if="form.PDF" class="ms-4">
                                <li class="ms-2">
                                    <a
                                        :href="`${documentRoot}/storage/draw/${form.PDF}`"
                                        :download="`${form.PDF}`"
                                        >{{ form.PDF }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                        <!-- ファイル -->
                        <div v-if="edit" class="row">
                            <file-upload
                                v-model="form"
                                v-model:filesModelValue="files"
                                :dg="dg"
                                :max-file-count="5"
                                :slice-num="30"
                                :document-root="documentRoot"
                            ></file-upload>
                        </div>
                        <div v-if="!edit" class="row">
                            <div class="col-12 mt-4">
                                {{ dg.SalTDraw.addFileAfterRegistered }}
                            </div>
                        </div>
                    </div>
                </div>
                <draw-input-table
                    v-model="form.salTInvoices"
                    v-model:checkedInvoiceModelValue="checkedInvoice"
                    :dg="dg"
                    :document-root="documentRoot"
                    :edit="edit"
                    :invoice-status-list="invoiceStatusList"
                    :draw-ym="form.DRAW_YM"
                    :is-loading="isLoading"
                    @paid="paid"
                    @print="print"
                    @export-to-bonedx="exportToBoneDX"
                ></draw-input-table>
            </div>
        </div>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <button
                type="button"
                class="btn btn-primary"
                v-if="edit"
                @click="csv"
                :disabled="isLoading"
            >
                {{ dg.csv }}
            </button>
            <button
                type="button"
                class="btn btn-danger"
                v-if="edit"
                @click="destroy"
                :disabled="isLoading"
            >
                {{ dg.Delete }}
            </button>
            <!-- 契約期間が終了した販売の状態を「終了」に変更して再表示ボタン -->
            <button
                type="button"
                class="btn btn-warning"
                v-if="hasContEndedSales && !edit"
                @click="reloadAfterChangeSalesStatusToEndContEnded"
                :disabled="isLoading"
            >
                {{ dg.SalTSales.reloadAfterChangeSalesStatusToEndContEnded }}
            </button>
            <!-- 中止ボタン -->
            <cancel-button
                :confirm-flag="changed"
                :confirm-message="dg.cancelMessage"
                :url="`${this.documentRoot}/draw`"
                :disabled="isLoading"
                >{{ dg.cancel }}</cancel-button
            >
            <button
                type="button"
                class="btn btn-primary"
                v-if="!edit"
                @click="create"
                :disabled="isLoading"
            >
                {{ dg.register }}
            </button>
            <button
                type="button"
                class="btn btn-primary"
                v-if="edit"
                @click="update"
                :disabled="isLoading"
            >
                {{ dg.update }}
            </button>
        </div>
    </form>

    <yes-no-modal2 ref="yesNoModal" :message="message" />
</template>

<script>
import moment from "moment";
import CancelButton from "../buttons/CancelButton.vue";
import FileUpload from "../inputs/FileUpload.vue";
import Mixin from "../mixins/mixin";
import YesNoModal2 from "../modals/YesNoModal2.vue";
import DrawInputTable from "./DrawInputTable.vue";

export default {
    name: "DrawInput",
    mixins: [Mixin],
    components: {
        CancelButton,
        DrawInputTable,
        YesNoModal2,
        FileUpload,
    },
    props: {
        data: Object,
        dg: Object,
        documentRoot: String,
        invoiceStatusList: Object,
        salesStatusEnd: Number,
    },
    data() {
        return {
            form: {},
            files: [],
            id: "",
            checkedInvoice: [],
            errors: [],
            edit: false,
            changed: false,
            sessionName: "drawInput",
            message: "",
            isLoading: false,
        };
    },
    computed: {
        contEndedSales() {
            const drawYm = moment(this.form.DRAW_YM).format("YYYY-MM-01");
            const contEndedSales = [];
            this.form.salTInvoices.forEach((item) => {
                item.salTInvoiceDtls
                    .filter((dtl) => dtl.CONT_END && dtl.CONT_END < drawYm)
                    .forEach((dtl) => {
                        if (
                            !contEndedSales.some(
                                (sale) => sale.SALES_NO === dtl.SALES_NO
                            )
                        ) {
                            contEndedSales.push({
                                SALES_NO: dtl.SALES_NO,
                                CONT_END: dtl.CONT_END,
                            });
                        }
                    });
            });
            return contEndedSales;
        },
        hasContEndedSales() {
            return this.contEndedSales.length > 0;
        },
    },
    mounted() {
        const unwatch = this.$watch(
            "form",
            () => {
                this.changed = true;
                unwatch();
            },
            { deep: true }
        );
    },
    created() {
        // プロパティ初期化
        this.init();
    },
    methods: {
        init() {
            this.form = this.data;
            this.edit = this.form.DRAW_NO ? true : false;
            this.form.INV_DATE = this.edit
                ? this.form.INV_DATE
                : moment(this.form.DRAW_YM).format("YYYY-MM-01");
            this.form.DRAW_DATE = this.edit
                ? this.form.DRAW_DATE
                : moment(this.form.DRAW_YM).format("YYYY-MM-20");
        },
        getFormData() {
            const formData = new FormData(this.$refs.form);

            Object.keys(this.form).forEach((key) => {
                if (this.form[key] == null) return;
                formData.set(key, this.form[key]);
            });

            formData.set("FILE1", "");
            formData.set("FILE2", "");
            formData.set("FILE3", "");
            formData.set("FILE4", "");
            formData.set("FILE5", "");

            for (var i = 0; i < this.files.length; i++) {
                if (this.files[i].file) {
                    formData.set("FILE" + (i + 1), this.files[i].file);
                } else {
                    formData.set("FILE" + (i + 1), this.files[i].name);
                }
            }

            return formData;
        },
        // 登録
        async create() {
            try {
                const res = await axios.post(`/draw`, this.form);
                location.href = res.data;
            } catch (error) {
                this.errorHandling(error);
            }
        },
        // 更新
        async update() {
            try {
                const res = await axios.post(
                    `/draw/${this.data.DRAW_NO}`,
                    this.getFormData(),
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "X-HTTP-Method-Override": "PUT",
                        },
                    }
                );
                location.href = res.data;
            } catch (error) {
                this.errorHandling(error);
            }
        },
        // 削除
        async destroy() {
            if (!confirm(this.dg.deleteConfirmMessage)) return;

            try {
                const res = await axios.delete(`/draw/${this.data.DRAW_NO}`);
                location.href = res.data;
            } catch (error) {
                this.errorHandling(error);
            }
        },
        // csv
        csv() {
            if (this._isChanged(this.changed)) return; // 変更されていたらリターン
            axios
                .get(`/draw/${this.data.DRAW_NO}/csv`, {
                    responseType: "blob",
                })
                .then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `${this.data.DRAW_NO}-${this.data.DRAW_YM}.csv`
                    );
                    document.body.appendChild(link);
                    link.click();
                });
        },
        // 印刷ボタン
        async print() {
            if (!this._isAtLeastOneSelected(this.checkedInvoice)) return;
            try {
                // 変更がある場合、変更を保存
                if (this.changed) {
                    if (!confirm(this.dg.changedPrintMessage)) return;
                    await axios.put(`/draw/${this.data.DRAW_NO}`, this.form);
                }
                this.isLoading = true;
                await axios.post(`/draw/${this.data.DRAW_NO}/pdf`, {
                    ID: this.checkedInvoice,
                });
                const res = await axios.post(
                    `/draw/${this.data.DRAW_NO}/update_status`,
                    {
                        ID: this.checkedInvoice,
                        // TODO: マジックナンバーを定数にする
                        STATUS: 1, // InvoiceStatus::getValue('Unsent') : 未送付
                        PAY_DATE: null,
                    }
                );
                location.href = res.data;
            } catch (error) {
                this.errorHandling(error);
            } finally {
                this.isLoading = false;
            }
        },
        // BoneDX連携が押された場合
        async exportToBoneDX() {
            if (!this._isAtLeastOneSelected(this.checkedInvoice)) return; // 最低1つ以上チェックされているか？

            if (
                !confirm(
                    `${this.checkedInvoice.length}${this.dg.SalTDraw.confirmForExportToBoneDXMessage}`
                )
            ) {
                return;
            }

            this.isLoading = true;
            try {
                const res = await axios.post(`/draw/export/bonedx`, {
                    ID: this.checkedInvoice,
                    DRAW_NO: this.data.DRAW_NO,
                });
                location.href = res.data;
            } catch (error) {
                this.errorHandling(error);
            } finally {
                this.isLoading = false;
            }
        },
        // 入金済
        async paid() {
            if (this._isChanged(this.changed)) return; // 変更されていたらリターン
            if (!this._isAtLeastOneSelected(this.checkedInvoice)) return; // 最低1つ以上チェックされているか？
            if (!this.form.PAY_DATE) {
                alert(this.dg.pleaseInputPayDate);
                return;
            }
            try {
                const licenseList = this.data.salTInvoices
                    ?.filter(({ ID }) => this.checkedInvoice.includes(ID))
                    .map(({ CLNIC_NM }) => CLNIC_NM)
                    ?.join("\n");

                this.message = `以下のライセンスを発行します。よろしいですか？\n${licenseList}`;
                const result = await this.$refs.yesNoModal.action();

                if (result) {
                    this.data.salTInvoices
                        .filter(({ ID }) => this.checkedInvoice.includes(ID))
                        .forEach(
                            async ({
                                KOKYK_ID,
                                salTInvoiceDtls,
                                mKokykLic,
                            }) => {
                                const RakkuinCodes = [
                                    "1010",
                                    "1011",
                                    "1012",
                                    "1019",
                                ];
                                const rakkuin = salTInvoiceDtls.find(
                                    ({ PRODUCT_CODE }) =>
                                        RakkuinCodes.includes(PRODUCT_CODE)
                                );

                                // らっく院対象コードがなければ、リターン
                                if (rakkuin) {
                                    const yukoStrYmd = moment(
                                        rakkuin.CONT_START
                                    ).format("YYYY-MM-DD");
                                    const yukoEndYmd = moment(
                                        rakkuin.CONT_START
                                    )
                                        .add(11, "M")
                                        .endOf("month")
                                        .format("YYYY-MM-DD");
                                    const syzkNo = mKokykLic.SYZK_NO;
                                    let planId = "";
                                    if (rakkuin.PRODUCT_CODE === "1010") {
                                        planId = "L";
                                    } else if (
                                        rakkuin.PRODUCT_CODE === "1011"
                                    ) {
                                        planId = "S";
                                    } else {
                                        planId = "P";
                                    }

                                    // TODO: 本番運用保留
                                    return;
                                    await axios.post(
                                        `${apiBaseUrl}/manage/v1/license/create`,
                                        {
                                            kokykId: KOKYK_ID,
                                            yukoStrYmd: yukoStrYmd,
                                            yukoEndYmd: yukoEndYmd,
                                            syzkNo: syzkNo,
                                            planId: planId,
                                        },
                                        {
                                            headers: {
                                                Authorization: `Bearer ${apiOuthToken}`,
                                            },
                                        }
                                    );
                                }
                            }
                        );
                }

                const res = await axios.post(
                    `/draw/${this.data.DRAW_NO}/update_status`,
                    {
                        ID: this.checkedInvoice,
                        STATUS: 9, // 終了
                        PAY_DATE: this.form.PAY_DATE,
                    }
                );

                location.href = res.data;
            } catch (error) {
                this.errorHandling(error);
            }
        },
        // 契約期間が終了した販売の状態を「終了」に変更して再表示
        async reloadAfterChangeSalesStatusToEndContEnded() {
            if (
                !confirm(
                    this.dg.SalTSales
                        .reloadAfterChangeSalesStatusToEndContEndedMessage
                )
            ) {
                return;
            }
            try {
                const res = await axios.post(`/sales/update_status`, {
                    SALES_NO: (this.contEndedSales || []).map(
                        ({ SALES_NO }) => SALES_NO
                    ),
                    STATUS: this.salesStatusEnd,
                });
                location.reload();
            } catch (error) {
                this.errorHandling(error);
            }
        },
    },
};
</script>
